import React from "react";
import renderHTML from "react-render-html";
import propTypes from "prop-types";
import { useHistory } from "react-router-dom";
import "./SubjectCard.css";

const SubjectCard = ({
  subjectId,
  subjectName,
  imageUrl,
  imageDesc,
  subjecColor,
  subjects,
  handleClick = "",
  idx
}) => {
  let history = useHistory();
  let cardSubjectStyle = `card-subject card border-1 shadow `;

  const navigateToSubjectTopics = () => {
    if (handleClick) {
      handleClick();
    }

    history.push({
      pathname: `/subjects/${subjectId}`
    });
    window.scrollTo(0, 0);
  };

  const onKeyPressEnterOrSpace = e => {
    if (e.keyCode === 32 || e.keyCode === 13) {
      navigateToSubjectTopics();
    }
    return;
  };

  if (idx) {
    cardSubjectStyle += idx % 2 ? "odd" : "even";
  }

  return (
    <div
      className={cardSubjectStyle}
      tabIndex={0}
      onClick={() => navigateToSubjectTopics()}
      onKeyDown={e => onKeyPressEnterOrSpace(e)}
      role="link"
      aria-label={`Explore the new grades 7-8 ${subjectName} resources`}
      style={{ borderColor: subjecColor }}
    >
      <div
        className="card-subject__header"
        style={{ backgroundColor: subjecColor }}
      ></div>
      {/* <img
        className="card-subject__icon"
        src={imageUrl}
        alt={imageDesc}
        typeof="foaf:Image"
      /> */}
      <div className="card-subject__body">
        <p
          className="card-subject__name text-center"
          style={{ color: subjecColor }}
        >
          {renderHTML(subjectName)}
        </p>
      </div>
    </div>
  );
};

SubjectCard.propTypes = {
  subjectId: propTypes.string,
  subjectName: propTypes.string,
  idx: propTypes.number
};

export default SubjectCard;
