import { useState, useEffect } from "react";
import axios from "axios/index";
import { useHistory } from "react-router-dom";
export const useForm = inputCategory => {
  //const [results, setResults] = useState([]);
  const [division, setDivision] = useState(
    sessionStorage.getItem("select-grades") || ""
  );
  const [search, setSearch] = useState(
    sessionStorage.getItem("search-term") || ""
  );
  const [query, setQuery] = useState("");
  let history = useHistory();

  const stripStopwords = term => {
    const stopWords = [
      "the",
      "of",
      "to",
      "in",
      "for",
      "is",
      "on",
      "that",
      "by",
      "with",
      "this",
      "be",
      "it",
      "www",
      "are",
      "as",
      "at",
      "i",
      "from",
      "a",
      "com",
      "an",
      "about",
      "was",
      "edu",
      "who",
      "what",
      "where",
      "when",
      "why",
      "how",
      "which",
      "de",
      "en",
      "la",
      "und",
      "&",
      "or"
    ];
    // Logic taken from https://github.com/rap2hpoutre/remove-stop-words
    stopWords.forEach(word => {
      let searchValue = new RegExp("\\b" + word + "\\b", "ig");
      term = term.replace(searchValue, "").trim();
    });

    return term === "" ? "%20" : term.replace(/ /g, "+");
  };

  const getTypeParams = (slug, category) => {
    let params = "";
    if (category === "all") {
      params = "filters[page][type][]=video";
      switch (slug) {
        case "tvotoday":
          params += "&filters[page][type][]=article";
          break;
        case "tvokids":
          params += "&filters[page][type][]=game";
          break;
        default:
          break;
      }
    } else {
      params = "filters[page][type][]=" + category;
    }
    return params;
  };

  useEffect(() => {
    const getResults = ({ division, query }) => {
      let category = inputCategory || "all";
      let page = "1";
      let historyPath = history.location.pathname.split("/");

      //update path coming from url path
      var SEARCH_ENDPOINT = process.env.REACT_APP_SEARCH_ENDPOINT;
      if (historyPath.length === 7) {
        category = historyPath[4];
        let paging =
          query.length > 0 && query !== historyPath[2] ? 1 : historyPath[3];
        page = paging;
        if (division.length === 0) {
          division = historyPath[6];
        }
        if (!query) {
          query = historyPath[2];
        }
      }

      if (query.length > 0) {
        var ENGINE_KEY = process.env.REACT_APP_KIDS_ENGINE_KEY;
        var SLUG = "tvokids";

        if (division === "tvoelementary" || division === "tvosecondary") {
          ENGINE_KEY = process.env.REACT_APP_TVO_ENGINE_KEY;

          SLUG = "tvotoday";
        }

        var REQ = `${SEARCH_ENDPOINT}/api/v1/public/engines/search.json?q=${query}+&page=${page}
      &per_page=10&engine_key=${ENGINE_KEY}&hightlight_fields=[page][body][size]=60
      &hightlight_fields=[page][body][fallback]=true&${getTypeParams(
          SLUG,
          category
        )}`;

        axios
          .get(REQ)
          .then(response => {
            const output = response.data;
            output["records"]["page"]["site"] = SLUG;
            output["records"]["page"]["division"] = division;
            output["records"]["page"]["term"] = query;
            output["records"]["page"]["category"] = category;
            output["records"]["page"]["page"] = page;
            output["records"]["page"]["total_num_pages"] =
              output.info.page.num_pages;
            history.push({
              pathname: `/search-educational-resources/${query}/${page}/${category}/${SLUG}/${division}`,
              state: { record: output.records.page }
            });
            window.scrollTo(0, 0);
          })
          .catch(error => console.log(error));
      }
    };

    getResults({ division, query });
  }, [division, query, history, inputCategory]);

  const updateDivision = e => {
    sessionStorage.setItem("select-grades", e.target.value);
    setDivision(e.target.value);
  };
  const updateSearch = e => {
    sessionStorage.setItem("search-term", e.target.value);
    setSearch(e.target.value);
  };
  const ExeSearch = e => {
    e.preventDefault();
    setQuery(stripStopwords(search));
  };
  return [updateSearch, ExeSearch, updateDivision, setDivision, setSearch];
};
