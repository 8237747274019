import React, { useEffect } from "react";
import "./Filter.css";
import { Link, useHistory } from "react-router-dom";

let searchTerm = "";

const Filter = ({ term, category, site, division, isMounted }) => {
  const typeUrl = site === "tvokids" ? "game" : "article";
  const type = site === "tvokids" ? "Games" : "Articles";

  const getActive = type => {
    return category === type ? "active" : "";
  };

  const history = useHistory();
  const pathArr = history.location.pathname.split("/");

  searchTerm = term;

  useEffect(() => {
    const currentSearchTerm = sessionStorage.getItem("search-term");
    if (!searchTerm || searchTerm !== currentSearchTerm) {
      searchTerm = currentSearchTerm;
    }
  }, []);

  return (
    <div className="search-filter view-filters">
      <ul className="flickity-filter global-search-filter">
        <li>
          <Link
            className={`type-filter-all ${getActive("all")}`}
            to={`/search-educational-resources/${searchTerm}/1/all/${site}/${division}`}
            title="All"
            tabIndex={1}
            onClick={e => {
              if (!isMounted || pathArr.includes("all")) {
                e.preventDefault();
              }
            }}
          >
            All
          </Link>
        </li>
        <li>
          <Link
            className={`type-filter-video ${getActive("video")}`}
            to={`/search-educational-resources/${searchTerm}/1/video/${site}/${division}`}
            title="Videos"
            tabIndex={1}
            onClick={e => {
              if (!isMounted || pathArr.includes("video")) {
                e.preventDefault();
              }
            }}
          >
            Videos
          </Link>
        </li>
        <li>
          <Link
            className={`type-filter-article ${getActive(typeUrl)}`}
            to={`/search-educational-resources/${searchTerm}/1/${typeUrl}/${site}/${division}`}
            title={type}
            tabIndex={1}
            onClick={e => {
              if (!isMounted || pathArr.includes(typeUrl)) {
                e.preventDefault();
              }
            }}
          >
            {type}
          </Link>
        </li>
      </ul>
    </div>
  );
};

export default React.memo(Filter);
