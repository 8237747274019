import React from "react";
import "./SubjectsCategory.css";
import SubjectCard from "./SubjectCard";
import useFetch from "../hooks/useFetch";
import BeatLoader from "react-spinners/BeatLoader";

const SubjectsCategory = () => {
  const [subjects, , subjectsLoading] = useFetch(
    `${process.env.REACT_APP_TVO_CMS_URL}/subjects`
  );

  return (
    <section
      id="block-subjects-category"
      className="subject-category text-center container-fluid clearfix"
    >
      <div className="p-1 p-md-2">
        <h2 className="subject-category__title mb-3">
          NEW! Featured resources for grades 7-8
        </h2>
        <p className="subject-category__text">
          Choose a subject below and explore new ways to support learning of the
          grade 7-8 curriculum. TVO educators have curated this section to
          connect engaging resources and learning prompts with curriculum.
        </p>
      </div>
      <div className="row">
        {subjectsLoading ? (
          <div className="d-flex my-5 justify-content-center align-items-center w-100">
            <BeatLoader color={"#ec1944"} loading={subjectsLoading} />
          </div>
        ) : (
          subjects &&
          subjects.map(
            (
              {
                name,
                tid,
                url_alias,
                field_subject_banner_image,
                field_subject_banner_colour
              },
              idx
            ) => {
              idx++;
              const imageArr =
                field_subject_banner_image &&
                field_subject_banner_image.split("||");
              let subjectImgUrl;
              let urlAlias = url_alias.replace("/", "");
              let subjectImgDesc;
              if (imageArr) {
                subjectImgUrl = imageArr[0];
                subjectImgDesc = imageArr[1];
              }
              return (
                <div
                  className="col-12 col-md-6 col-lg-3 card-group p-2 my-sm-3 w-100"
                  key={tid}
                >
                  <SubjectCard
                    subjectName={name}
                    subjectId={urlAlias}
                    imageUrl={subjectImgUrl}
                    imageDesc={subjectImgDesc}
                    subjecColor={field_subject_banner_colour}
                    subjects={subjects}
                    idx={idx}
                  />
                </div>
              );
            }
          )
        )}
      </div>
    </section>
  );
};

export default SubjectsCategory;
