import React from "react";
import "./Intro.css";
const Intro = () => {
  return (
    <section
      id="block-citcbrandinglogo"
      className="block block-tvo-citc-search block-tvo-citc-search-branding-logo-block clearfix"
    >
      <div className="row mb-4 d-flex justify-content-center justify-content-lg-between">
        <div className="col-lg-7 align-self-center content-intro">
          <h1 className="block-title text-center text-lg-left">
            Discover the power of learning with TVO in the Classroom
          </h1>
          <p className="text-center text-lg-left text-intro">
            Introducing interactive ways educators, parents and students can
            connect to the Ontario K-12 curriculum. Boost engagement and
            learning impact in-person or online. Bring TVO’s in-depth Current
            Affairs, thought-provoking documentaries, and award-winning TVOkids
            videos and games into your lessons today.
          </p>
        </div>
        <div className="col-md-3 image-intro">
          <img
            className="img-fluid border-0  "
            src={require("../../assets/images/titc-hero-new.png")}
            alt="TVO in the Classroom - Teacher and Student with Tablet"
          />
        </div>
      </div>
    </section>
  );
};
export default Intro;
