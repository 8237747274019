import React from "react";
import renderHTML from "react-render-html";
import "./Pages/Search.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExternalLinkAlt } from "@fortawesome/free-solid-svg-icons";
import SponsoredContentLabel from "./SponsoredContentLabel/SponsoredContentLabel";

const DFOSearch = ({ title, thumbnail, desc, link, type }) => {
  let alt = title;
  let cssClass = "";

  if (thumbnail.length === 0) {
    cssClass = "hide-image";
  }

  return (
    <section className="view-content-listing">
      <div className="search-results-wrapper">
        <div className="search-result views-row search-result-type-video result-row is-spnsr">
          <div className="row">
            <div
              className={`col-left col-md-4 views-image-fieldset ${cssClass}`}
            >
              <a href={link} target="_blank" rel="noopener noreferrer">
                <div className={`thumbnail-wrap bc-thumb-wrapper ${type}`}>
                  {thumbnail.length > 0 ? (
                    <img src={thumbnail} alt={alt} typeof="foaf:Image" />
                  ) : (
                      ""
                    )}
                </div>
              </a>
            </div>

            <div
              className={
                thumbnail.length > 0
                  ? "views-content-fieldset col-md-7 col-lg-8"
                  : "views-content-fieldset col-md-12"
              }
            >
              <div className="row">
                <div className="col-md-12 col-lg-8 views-content-wrapper">
                  <SponsoredContentLabel
                    text="Sponsored Content"
                    from="search"
                  />
                  <div>
                    <h2 className="views-field-title title spnsr-title">
                      <a href={link} target="_blank" rel="noopener noreferrer">
                        {renderHTML(title)}
                        {
                          <FontAwesomeIcon
                            icon={faExternalLinkAlt}
                            size="xs"
                            color="#253D98"
                            className="external-link-alt"
                          />
                        }
                      </a>
                    </h2>
                  </div>
                  <div className="spnsr-by-message">
                    Sponsored by Dairy Farmers of Ontario
                  </div>

                  <p className="search-snippet">
                    <span className="views-field-field-summary">
                      {desc.length > 0 ? renderHTML(desc) : ""}
                    </span>
                  </p>
                  <div className="spnsr-link">via edu.milk.org</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default DFOSearch;
