import React, { useEffect } from "react";
import "./MainSearchForm.css";
import { useForm } from "../hooks/useForm";
import propTypes from "prop-types";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';

const MainSearchForm = ({
  labelName,
  options,
  term,
  division,
  isBorder,
  from,
  category
}) => {
  const [updateSearch, ExeSearch, updateDivision, setDivision] = useForm(
    category
  );
  let additionalClass = "";

  term = typeof term !== "undefined" ? term : "";

  useEffect(() => {
    const currentSelectedGrade = sessionStorage.getItem("select-grades");
    if (currentSelectedGrade && division !== currentSelectedGrade) {
      setDivision(currentSelectedGrade);
    }
  }, [division, setDivision]);

  if (isBorder) {
    additionalClass = "border shadow";
  }
  return (
    <section
      className={`fluid-container ${additionalClass} main-search-container clearfix my-4`}
    >
      {from && from === "searchResult" && (
        <div className="form-row search-result-label-containner">
          <h1 htmlFor="citc-search-word" className="mb-4 search-result-label">
            {labelName}
          </h1>
        </div>
      )}
      <form
        onSubmit={ExeSearch}
        className={`${from === "home" ? "p-4 p-md-5" : ""} search-form`}
      >
        {from && from === "home" && labelName && (
          <h2
            htmlFor="citc-search-word"
            className="home-search-title control-label mb-4"
          >
            {labelName}
          </h2>
        )}
        <div className="form-row justify-content-center">
          <div className="col col-lg-5 form-group input-group form-group-sm">
            <div className="input-group-prepend">
              <span className="input-group-text border-right-0">
                <FontAwesomeIcon icon={faSearch} />
              </span>
            </div>
            <input
              type="text"
              className="form-text form-control my-0 py-2 border-left-0"
              placeholder="Enter your search term"
              onChange={updateSearch}
              name="citc-search-word"
              aria-label="Enter your search term"
              defaultValue={sessionStorage.getItem("search-term") || ""}
            />
          </div>
          <div className="col-12 col-lg-3 form-group  mx-2 select">
            <select
              id="youthGroup"
              className="form-control py-2"
              onChange={updateDivision}
              onBlur={updateDivision}
              required
              aria-label="Select student grade level"
              aria-required="true"
              defaultValue={sessionStorage.getItem("select-grades") || ""}
            >
              <option value="">Please select a grade level*</option>
              {options &&
                options.map(({ name, value }) => (
                  <option
                    key={`dropdown-${value.replace(" ", "")}`}
                    value={value}
                  >
                    {name}
                  </option>
                ))}
            </select>
          </div>
          <div
            data-drupal-selector="edit-actions"
            className="form-actions form-group js-form-wrapper form-wrapper"
            id="edit-actions"
          >
            <button
              type="submit"
              className="button js-form-submit form-submit btn-primary"
              id="edit-submit"
              name="op"
              value="Search"
            >
              Search
            </button>
          </div>
        </div>
      </form>
    </section>
  );
};

MainSearchForm.propTypes = {
  labelName: propTypes.string,
  options: propTypes.array
};

export default React.memo(MainSearchForm);
