import React, { useState, useEffect } from "react"

import "./Pagination.css"
import { Pagination } from "semantic-ui-react"
import axios from "axios/index"
import { useHistory } from "react-router-dom"
import Filter from "../Filter/Filter"
import MainSearchForm from "../MainSearchForm/MainSearchForm"
import "./Search.css"
import { generateBreadCrumb } from "../Breadcrumb/Breadcrumb"
import NodeSearchItem from "../NodeSearchItem"
import DFOSearch from "../DFOSearch"

const MAINSEARCH_LABEL = "Search Results"
export const options = [
  { name: "K-4", value: "tvokids" },
  { name: "5-8", value: "tvoelementary" },
  { name: "9-12", value: "tvosecondary" }
]

const SearchResults = props => {
  const history = useHistory()
  const [isMounted, setIsMounted] = useState(false)

  let query = ""

  useEffect(() => {
    setIsMounted(true)
    return () => {
      setIsMounted(false)
    }
  }, [props])

  const historyPath = history.location.pathname.split("/")
  const [path, setPath] = useState("")

  useEffect(() => {
    setPath(historyPath[4])
  }, [history.location.pathname, historyPath])

  const response = { props }
  let results =
    typeof response.props.location.state !== "undefined"
      ? response.props.location.state.record
      : response.props.match.params
  const total_num_pages =
    typeof response.props.location.state !== "undefined"
      ? response.props.location.state.record.total_num_pages
      : 1

  const breadCrumb = generateBreadCrumb(historyPath)

  let category = "all"
  let page =
    typeof response.props.location.state !== "undefined"
      ? response.props.location.state.record.page
      : 1
  let { division } = results
  let activePage = page
  const getTypeParams = (slug, category) => {
    let params = ""
    if (category === "all") {
      params = "filters[page][type][]=video"
      switch (slug) {
        case "tvotoday":
          params += "&filters[page][type][]=article"
          break
        case "tvokids":
          params += "&filters[page][type][]=game"
          break
        default:
          params += "&filters[page][type][]=article"
      }
    } else {
      params = `filters[page][type][]=${category}`
    }
    return params
  }
  const onChange = (e, pageInfo) => {
    const SEARCH_ENDPOINT = process.env.REACT_APP_SEARCH_ENDPOINT
    activePage = pageInfo.activePage
    query =
      typeof history.location.state !== "undefined"
        ? history.location.state.record.term
        : ""
    // update path coming from url path
    if (historyPath.length === 7) {
      category = historyPath[4]
      page = activePage !== page ? activePage : historyPath[3]
      if (division.length === 0) {
        division = historyPath[6]
      }
      if (!query) {
        query = historyPath[2]
      }
    }

    if (query.length > 0) {
      let ENGINE_KEY = process.env.REACT_APP_KIDS_ENGINE_KEY
      let SLUG = "tvokids"

      if (division === "tvoelementary" || division === "tvosecondary") {
        ENGINE_KEY = process.env.REACT_APP_TVO_ENGINE_KEY
        SLUG = "tvotoday"
      }
      const REQ = `${SEARCH_ENDPOINT}/api/v1/public/engines/search.json?q=${query}+&page=${activePage}
    &per_page=10&engine_key=${ENGINE_KEY}&hightlight_fields=[page][body][size]=60
    &hightlight_fields=[page][body][fallback]=true&${getTypeParams(
      SLUG,
      category
    )}`
      axios
        .get(REQ)
        .then(response => {
          const output = response.data
          output.records.page.site = SLUG
          output.records.page.division = division
          output.records.page.term = query
          output.records.page.category = category
          output.records.page.page = page
          output.records.page.total_num_pages = output.info.page.num_pages
          history.push({
            pathname: `/search-educational-resources/${query}/${activePage}/${category}/${SLUG}/${division}`,
            state: { record: output.records.page }
          })
          window.scrollTo(0, 0)
        })
        .catch(error => console.log(error))
    }
  }

  // this can be reused to display sponsored content
  // const placeContentDFO = (randomContent, result, index) => {
  //   result.combined = true
  //   if (!result.length || (result.length > 0 && !randomContent.length))
  //     return result
  //   if (!result.length && randomContent.length > 0)
  //     return result.push(randomContent)
  //   if (result.length > 0 && randomContent.length > 0)
  //     return result.splice(index, 0, ...randomContent)

  //   result.combined = false
  //   return result
  // }

  const removeDuplicates = data => {
    const newData = Object.values(
      data.reduce(
        (r, article, i) => ({
          ...r,
          [article.nid || article.ID]: { i, article }
        }),
        {}
      )
    )
      .sort((a, b) => a.i - b.i)
      .map(n => n.article)
    data.length = 0
    return Object.assign(newData, data)
  }

  results = Array.isArray(results) ? removeDuplicates(results) : results

  return (
    <div
      role="main"
      className="main-container container js-quickedit-main-content clearfix"
    >
      <div className="region region-content search-results-content">
        {breadCrumb && breadCrumb}

        <MainSearchForm
          labelName={MAINSEARCH_LABEL}
          options={options}
          term={results.term}
          division={results.division}
          from="searchResult"
          category={path}
        />
        <div className="row">
          <div className="col-md-2 listing-filter-wrapper">
            <Filter
              term={results.term}
              page={results.page}
              category={results.category}
              site={results.site}
              division={results.division}
              isMounted={isMounted}
            />
          </div>
          <div className="view-content search-results-wrapper col-md-10">
            <div className="search-results content-list content-list--search">
              {results.length === 0 ? (
                <div className="no-result-wrapper view-content">
                  <h3>No Results Found</h3>
                  <ul>
                    <li>Check if your spelling is correct.</li>
                    <li>Try more general or different keywords.</li>
                  </ul>
                </div>
              ) : (
                results &&
                !!results.length &&
                results.map((items, i) => {
                  const { nid, desc, title, alt, image, type } = items
                  if (isMounted) {
                    // this line can be change to check sponsored content in the future
                    if (items && items.Source === "DFO") {
                      if (+activePage === 1) {
                        return (
                          <DFOSearch
                            key={items.ID}
                            title={items.Title}
                            thumbnail={items.Thumbnail}
                            desc={items.Description}
                            link={items.Links.UTMs}
                            type={items.Source}
                          />
                        )
                      }
                    } else {
                      return (
                        <NodeSearchItem
                          key={nid}
                          nid={nid}
                          desc={desc}
                          title={title}
                          alt={alt}
                          img={image}
                          type={type}
                          site={results.site}
                          topic={false}
                        />
                      )
                    }
                  }
                  return ""
                })
              )}
            </div>
            {results.site !== "" && results.length > 0 && (
              <Pagination
                prevItem={activePage === "1" ? null : undefined}
                activePage={activePage}
                onPageChange={onChange}
                totalPages={total_num_pages}
                ellipsisItem={undefined}
                firstItem={null}
                lastItem={null}
                boundaryRange={0}
                siblingRange={2}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  )
}
export default React.memo(SearchResults)
