import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import axios from "axios/index";

export const useNodeSearch = (type, nodeid, site, match) => {
  let nid;
  let siteName;
  if (match) {
    nid = match.params.nid;
    siteName = match.params.site;
  } else {
    nid = nodeid;
    siteName = site;
  }
  let history = useHistory();
  const [result, setResult] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    let data = [];
    const getResult = () => {
      let URL = process.env.REACT_APP_TVO_CMS_URL;
      let domain = "https://www.tvo.org";
      let path = "tvo";
      setLoading(true);
      if (siteName === "tvokids") {
        domain = "https://www.tvokids.com";
        path = "kids";
        URL = process.env.REACT_APP_TVOKIDS_URL;
      }
      const REQ = `${URL}/${path}-contents/${nid}`;

      axios
        .get(REQ)
        .then(response => {
          if (path === "tvo") {
            data = response.data[0] || [];
          } else {
            data =
              (response.data.nodes[0] && response.data.nodes[0].node) || [];
          }
          if (match) {
            data["domain"] = domain;
            data["site"] = siteName;
          } else {
            data["domain"] = "";
            data["site"] = "";
          }
          setLoading(false);
          setResult(data);
        })
        .catch(error => {
          setLoading(false);
          console.log(error);
        });
    };
    getResult();
  }, [match, nid, siteName]);

  return [history, result, loading];
};
