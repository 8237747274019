import React from "react";
import renderHTML from "react-render-html";
import "./Pages/Search.css";
import { useNodeSearch } from "./hooks/useNodeSearch";
import { Link } from "react-router-dom";
import { defaultImageUrlIfNotExists } from "../util/index";

const NODESearch = ({ type, nodeid, site, topic, match, from }) => {
  const [, result] = useNodeSearch(type, nodeid, site, match);

  let img = [];
  let src = "no image";
  let alt = "";
  let cssClass = "";
  let result_title = "";
  let desc = "";
  let link_text = "";
  let link = "";
  let age = "";

  const secondsToTimeConverter = timeInSeconds => {
    let seconds = (timeInSeconds / 1000).toFixed(0),
      minutes = Math.floor(seconds / 60),
      hours = "";
    if (minutes > 59) {
      hours = Math.floor(minutes / 60);
      hours = hours >= 10 ? hours : "0" + hours;
      minutes = minutes - hours * 60;
      minutes = minutes >= 10 ? minutes : "0" + minutes;
    }
    seconds = Math.floor(seconds % 60);
    seconds = seconds >= 10 ? seconds : "0" + seconds;
    if (hours !== "") {
      return hours + ":" + minutes + ":" + seconds;
    }
    return minutes + ":" + seconds;
  };

  const durationStringConverter = duration => {
    let result = duration;
    if (!result) {
      return result;
    }
    if (!isNaN(duration)) {
      if (duration.length <= 2) {
        result = result.concat(":00");
      } else {
        result = secondsToTimeConverter(duration);
      }
    } else {
      if (!result.includes(":")) {
        return "";
      }
    }
    return result;
  };

  if (!result || result.length === 0) {
    return null;
  } else {
    if (typeof result.field_small_tile_image !== "undefined") {
      src = result.field_small_tile_image.src;
      alt = result.field_small_tile_image.alt;
      result_title = result.title;
    } else {
      result_title = result.title.split("|");
      result_title = result_title[0];
      if (result.article_thumbnail !== "") {
        img = result.article_thumbnail.split("||");
        src = img[0];
        alt = img[1];
      }
      if (result.video_thumbnail !== "") {
        img = result.video_thumbnail.split("||");
        src = img[0];
        alt = img[1];
      }
    }
    switch (type) {
      case "Article":
        desc = result.article_summary;
        link_text = topic ? "Read" : null;
        break;
      case "Video":
        desc =
          site === "tvokids" ? result.field_short_description : result.summary;
        link_text =
          topic && result.field_tag === "Podcast" ? "Listen" : "Watch";
        break;
      case "Game":
        desc = result.field_description;
        link_text = "Play";
        break;
      default:
        desc = "";
    }

    if (src === "no image") {
      cssClass = "hide-image";
    }

    if (site === "tvotoday") {
      link = topic
        ? `${process.env.REACT_APP_TVO_URL}${result.link}`
        : `/search-educational-resources/${nodeid}/${site}`;
    } else if (site === "tvokids") {
      link = topic
        ? `${process.env.REACT_APP_TVOKIDS_URL}${result.path}`
        : `/search-educational-resources/${nodeid}/${site}`;
    }

    age =
      topic && result.field_grades
        ? result.field_grades.replace(/\|\|/g, ", ")
        : "";
  }
  let ariaLabel = "";
  if (link_text) {
    ariaLabel = `${link_text} ${
      link_text.toLowerCase() === "read" ? "article" : "video"
    } ${renderHTML(result_title)}`;
  }

 

  return (
    <section className="view-content-listing">
      <div className="search-results-wrapper">
        <div className="search-result views-row search-result-type-video result-row">
          <div className="row">
            <div
              className={`col-left col-md-4 views-image-fieldset ${cssClass}`}
            >
              <a href={link} target={topic ? "_blank" : "_self"}>
                <div
                  className={`thumbnail-wrap bc-thumb-wrapper ${result.type}`}
                >
                  {src !== "no image" ? (
                    <img
                      src={src}
                      alt={alt}
                      typeof="foaf:Image"
                      onError={defaultImageUrlIfNotExists}
                    />
                  ) : (
                    ""
                  )}
                  <div className="thumbnail-overlay">{type}</div>
                </div>
              </a>
            </div>

            <div
              className={
                src !== "no image"
                  ? "views-content-fieldset col-md-7 col-lg-8"
                  : "views-content-fieldset col-md-12"
              }
            >
              <div className="row">
                <div className="col-md-12 col-lg-8 views-content-wrapper">
                  {from && from === "topic-resources" && (
                    <h3 className="views-field-title title">
                      <a
                        href={link}
                        className="result-title"
                        data-citc="data-citc-search"
                      >
                        {renderHTML(result_title)}
                      </a>
                    </h3>
                  )}

                  {from && from === "search-results" && (
                    <h2 className="views-field-title title">
                      <Link
                        to={{ pathname: `${link}`, state: "search-result" }}
                        className="result-title"
                        data-citc="data-citc-search"
                      >
                        {renderHTML(result_title)}
                      </Link>
                    </h2>
                  )}

                  <p className="search-snippet">
                    <span className="views-field-field-summary">
                      {typeof desc !== "undefined" ? renderHTML(desc) : ""}
                    </span>
                  </p>
                  {topic && (
                    <p className="views-field-grades-rating-wrapper">
                      <span className="views-field-grades">Grade {age}</span>
                      {result.field_canadian_broadcast_rating && (
                        <span className="views-field-grades-rating-wrapper mr-2">
                          |
                          <span className="views-field-rating">
                            {result.field_canadian_broadcast_rating}
                          </span>
                        </span>
                      )}
                      {result.field_length && (
                        <span className="views-field-grades-rating-wrapper">
                          |
                          <span className="views-field-length">
                            {durationStringConverter(result.field_length)}
                          </span>
                        </span>
                      )}
                    </p>
                  )}
                </div>
                {topic && (
                  <div className="col-md-12 col-lg-4 vertical-align-weird-screen">
                    <a
                      href={link}
                      className="btn btn-default btn-primary"
                      target="_blank"
                      rel="noopener noreferrer"
                      aria-label={ariaLabel}
                    >
                      {link_text}
                    </a>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default NODESearch;
