import React from "react";
import FeaturedBlocks from "../FeaturedBlocks/FeaturedBlocks";
import Intro from "../Intro/Intro";
import SubjectsCategory from "../SubjectsCategory/SubjectsCategory";
import MainSearchForm from "../MainSearchForm/MainSearchForm";
import MathifyBanner from "../MathifyBanner/MathifyBanner";
import Head from "../Head/Head";

const MAINSEARCH_LABEL =
  "Search 10,000+ videos, articles and games to enhance K-12 learning!";
export const options = [
  { name: "K-4", value: "tvokids" },
  { name: "5-8", value: "tvoelementary" },
  { name: "9-12", value: "tvosecondary" }
];

const Home = () => {
  return (
    <div className="wrap-containers">
      <Head
        title="TVO in the Classroom | TVO.org"
        description="TVO in the Classroom provides interactive ways educators, parents, and students can connect to the Ontario K-12 curriculum.  Boost engagement and learning impact in-person or online."
        image="/static/media/titc_hero_1.ff4702fa.png"
      />
      <div role="main" className="main-container">
        <section
          id="block-citcintro"
          className="block-tvo-citc-search-intro-block"
        >
          <Intro />
        </section>
        <SubjectsCategory />
        <MathifyBanner />
        <MainSearchForm
          labelName={MAINSEARCH_LABEL}
          options={options}
          isBorder={true}
          from="home"
        />
        <FeaturedBlocks />
      </div>
    </div>
  );
};
export default Home;
