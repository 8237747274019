import React from "react";
import SuggestedActivities from "../SuggestedActivities/SuggestedActivities";
import { useTopics } from "../hooks/useTopics";
import TopicResources from "../TopicResources/TopicResources";
import "../TopicResources/TopicResources.css";
import SubjectTopicsHeader from "../SubjectTopics/SubjectTopicsHeader";
import LearningObjectives from "../LearningObjectives/LearningObjectives";
import Head from "../Head/Head";
import renderHTML from "react-render-html";
import BeatLoader from "react-spinners/BeatLoader";

const TopicDetails = ({ match, location }) => {
  const [result, loading] = useTopics(match);

  let contents = [];
  let suggestActivities = [];
  let learningObjectives = "";
  let topicSubcategories = [];
  let subjectId = match.params.subject;
  let subjectName = "";
  let imageUrl = "";
  let imageDesc = "";
  let subjectColor = "";
  let topic = "";
  let tid = match.params.topic;
  const parent = "topic-details";

  let headObj = {
    title: "",
    description: "",
    ogImage: "",
    ogTitle: "",
    ogDescription: ""
  };

  if (result.topics) {
    if (Array.isArray(result.topics)) {
      return null;
    }
    contents = result.topics.contents;
    suggestActivities = result.topics.suggested_activities;
    learningObjectives = result.topics.learning_objectives;
    let subcategories = result.topics.subject_area;
    subcategories.map((subject_area, index) => {
      if (subject_area.parent_id.url_alias === "/" + subjectId) {
        subjectName = subject_area.parent_id.name;
        subjectColor = subject_area.parent_id.subject_color_code;
      }

      subject_area.children.map((item, i) => {
        let { id, name } = item;
        topicSubcategories.push({
          id: id,
          name: name
        });
        return null;
      });
      return null;
    });

    topic = result.topics.name;
    let metaTags = result.topics.metatags;

    if (metaTags) {
      headObj.title = metaTags.title;
      headObj.description = metaTags.description;
      headObj.ogImage = metaTags.og_image;
      headObj.ogDescription = metaTags.og_description;
      headObj.ogTitle = metaTags.og_title;
    } else {
      headObj.title = headObj.ogTitle = `${renderHTML(
        topic
      )} | TVO in the Classroom`;
      if (learningObjectives) {
        if (!Array.isArray(renderHTML(learningObjectives))) {
          headObj.description = headObj.ogDescription = renderHTML(
            learningObjectives
          ).props.children[0];
        } else {
          headObj.description = headObj.ogDescription = [
            ...renderHTML(learningObjectives)[0].props.children
          ].flatMap(x => x.split("."))[0];
        }
      }
    }
  }

  return (
    <div className="wrap-containers">
      <Head
        title={headObj.title}
        description={headObj.description}
        ogImage={headObj.ogImage}
        ogTitle={headObj.ogTitle}
        ogDescription={headObj.ogDescription}
      />
      <div
        role="main"
        className="main-topics-container subject-area-wrapper container-fluid clearfix"
      >
        <SubjectTopicsHeader
          subject={subjectName}
          image={imageUrl}
          alt={imageDesc}
          introText={topic}
          subjectColor={subjectColor}
          subjectId={subjectId}
          parent={parent}
        />
        {loading && (
          <div className="d-flex my-5 justify-content-center align-items-center w-100">
            <BeatLoader color={"#ec1944"} loading={loading} />
          </div>
        )}
        {!loading && !result.topics && (
          <div className="no-result-wrapper view-content">
            <h3>No Results Found</h3>
          </div>
        )}
        {result.topics && learningObjectives && (
          <LearningObjectives
            learningObjectives={learningObjectives}
            // topic={"Learning Units"}
            topic={
              topicSubcategories.length > 1
                ? "Curriculum Strands"
                : "Curriculum Strand"
            }
            topicSubcategories={topicSubcategories}
            subjectId={subjectId}
            subjectColor={subjectColor}
          />
        )}
        {result.topics && contents.length > 0 && (
          <section className="featured-content-container">
            <h2
              className="topics-featured-content"
              style={{ borderColor: subjectColor }}
            >
              Featured TVO Content
            </h2>
            <div className="topic-resources content-list content-list--topics">
              {contents &&
                contents.map((items, i) => {
                  return (
                    <TopicResources
                      key={items.nid}
                      type={items.type}
                      nodeid={items.nid}
                      site={items.site}
                    />
                  );
                })}
            </div>
          </section>
        )}
        {result.topics && suggestActivities && suggestActivities.length > 0 && (
          <SuggestedActivities
            activities={suggestActivities}
            tid={tid}
            subjectColor={subjectColor}
          />
        )}
      </div>
    </div>
  );
};

export default TopicDetails;
