import React from "react";
import "./Search.css";
import { useNodeSearch } from "../hooks/useNodeSearch";
import { useHistory } from "react-router-dom";
import renderHTML from "react-render-html";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { generateBreadCrumb } from "../../Components/Breadcrumb/Breadcrumb";
import BeatLoader from "react-spinners/BeatLoader";
import Head from "../Head/Head";

const SearchDetails = ({ match }, props) => {
  var type = "";
  var nodeid = "";
  var site = "";
  let img = [];
  let src = "";
  let alt = "";
  let path = "";
  let program_summary = "";
  let node_type = "";
  let description = "";
  let usehistory = useHistory();
  let historyPath = usehistory.location.pathname.split("/");
  const [history, result, loading] = useNodeSearch(type, nodeid, site, match);
  const breadCrumb = generateBreadCrumb(historyPath, history, result.title);
  if (result.field_short_description) {
    description = result.field_short_description;
  } else {
    description = result.field_description;
  }

  if (loading) {
    return (
      <div
        className="d-flex mt-5 justify-content-center align-items-center"
        style={{ height: "100vh" }}
      >
        <BeatLoader color={"#ec1944"} loading={loading} />
      </div>
    );
  } else {
    if (result.length === 0) {
      return null;
    } else {
      if (result.site === "tvokids") {
        path = result.path;
        node_type = result.type;
        program_summary = result.field_brand_page_description;
      } else {
        path = result.link;
        if (result.type === "article") {
          img = result.article_thumbnail.split("||");
          src = img[0];
          alt = img[1];
          node_type = "Article";
          description = result.article_summary;
        } else {
          img = result.video_thumbnail.split("||");
          src = img[0];
          alt = img[1];
          node_type = "Video";
          description = result.summary;
          program_summary = result.program_summary;
        }
      }
    }
  }

  return (
    <div className="titc-details-page">
      <Head
        title={result.title}
        description={description}
        ogImage=""
        ogTitle={result.title}
        ogDescription={description}
      />
      <div
        role="main"
        className="main-container container js-quickedit-main-content clearfix"
      >
        <div className="search-detail-wrapper">
          {breadCrumb && breadCrumb}
          <h1 className="page-title">{renderHTML(result.title)}</h1>

          <div className="content-details">
            <div className="row content-details">
              <div className="col-lg-6">
                <div
                  className={`thumbnail-wrap bc-thumb-wrapper ${result.type}`}
                >
                  <a
                    href={`${result.domain}${path}`}
                    data-citc="data-citc-details"
                    data-citc-value="leaves"
                  >
                    {result.site === "tvokids" ? (
                      <img
                        src={result.field_small_tile_image.src}
                        alt={result.field_small_tile_image.alt}
                        typeof="foaf:Image"
                      />
                    ) : (
                      <img src={src} alt={alt} typeof="foaf:Image" />
                    )}
                    <div className="thumbnail-overlay">
                      {(node_type === "Game" ? "Play " : "Watch ") + node_type}
                    </div>
                  </a>
                </div>
              </div>
              <div className="col-lg-6">
                <VideoExpiry type={result.type} date={result.unpublish_on} />
                <h2>Direct Link</h2>
                <div className="row link-copy">
                  <div className="col-lg-6">
                    <input
                      type="text"
                      name="direct-link"
                      id="direct-link"
                      defaultValue={`${result.domain}${path}?utm_source=citc&amp;utm_medium=citc&amp;utm_campaign=citc`}
                    />
                  </div>
                  <div className="col-lg-4">
                    <CopyToClipboard
                      text={`${result.domain}${path}?utm_source=citc&amp;utm_medium=citc&amp;utm_campaign=citc`}
                    >
                      <button>Copy direct link</button>
                    </CopyToClipboard>
                  </div>
                </div>
              </div>
            </div>
            <div className="row content-details">
              <div
                className={
                  program_summary.length > 0 ? "col-lg-6" : "col-lg-12"
                }
              >
                <div className="content-description">
                  <h2>{`${node_type} Description`}</h2>

                  <p>
                    {typeof description !== "undefined"
                      ? renderHTML(description)
                      : ""}
                  </p>
                </div>
              </div>
              <ProgramDescription type={result.type} desc={program_summary} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const ProgramDescription = ({ type, desc }) => {
  if (type === "Video" || type === "video") {
    return (
      <div className="col-lg-6">
        <div className="content-description">
          <h2>Program Description</h2>
          <p> {renderHTML(desc)}</p>
        </div>
      </div>
    );
  } else {
    return null;
  }
};

const VideoExpiry = ({ type, date }) => {
  if (type === "Video" || type === "video") {
    return (
      <div className="expiry">
        <h2>Expires On</h2>
        <p>{date}</p>
      </div>
    );
  } else {
    return null;
  }
};

export default SearchDetails;
