import React from "react";
import renderHTML from "react-render-html";
import "./Pages/Search.css";
import propTypes from "prop-types";
import { Link } from "react-router-dom";
import { defaultImageUrlIfNotExists } from "../util/index";

const NodeSearchItem = ({ nid, desc, title, alt, img, type, site, topic }) => {
  const src = img ? img : "no image";
  const cssClass = src === "no image" ? "hide-image" : "";
  const link = `/search-educational-resources/${nid}/${site}`;
  const splittedTitle = title ? title.split("|")[0].trim() : "";
  const contentType =
    type !== "article" ? type.charAt(0).toUpperCase() + type.slice(1) : "";

  return (
    <section className="view-content-listing">
      <div className="search-results-wrapper">
        <div className="search-result views-row search-result-type-video result-row">
          <div className="row">
            <div
              className={`col-left col-md-4 views-image-fieldset ${cssClass}`}
            >
              <a href={link} target={topic ? "_blank" : "_self"}>
                <div
                  className={`thumbnail-wrap bc-thumb-wrapper ${contentType}`}
                >
                  {src !== "no image" ? (
                    <img
                      src={src}
                      alt={alt}
                      typeof="foaf:Image"
                      onError={defaultImageUrlIfNotExists}
                    />
                  ) : (
                    ""
                  )}
                  <div className="thumbnail-overlay">{contentType}</div>
                </div>
              </a>
            </div>

            <div
              className={
                src !== "no image"
                  ? "views-content-fieldset col-md-8"
                  : "views-content-fieldset col-md-12"
              }
            >
              <div className="row">
                <div className="col-md-12 col-lg-8 views-content-wrapper">
                  <h2 className="views-field-title title">
                    <Link
                      to={{ pathname: `${link}`, state: "search-result" }}
                      className="result-title"
                      data-citc="data-citc-search"
                    >
                      {renderHTML(splittedTitle)}
                    </Link>
                  </h2>
                  <p className="search-snippet">
                    <span className="views-field-field-summary">
                      {typeof desc !== "undefined" ? renderHTML(desc) : ""}
                    </span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

NodeSearchItem.propTypes = {
  nid: propTypes.number,
  desc: propTypes.string,
  title: propTypes.string,
  alt: propTypes.string,
  type: propTypes.string,
  site: propTypes.string,
  topic: propTypes.bool
};

export default React.memo(NodeSearchItem);
