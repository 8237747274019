/**
 * @function defaultImageUrlIfNotExists()
 *
 * @summary Provide a image fallback if video's thumbnail is missing
 *
 * @author Ray Jiang
 *
 * Created at     : 2021-01-25 14:10:49
 * Last modified  : 2021-01-25 16:29:15
 */

export const defaultImageUrlIfNotExists = e => {
  e.target.onerror = null;
  e.target.src = `${process.env.REACT_APP_TVO_CMS_URL}/modules/custom/tvo_brightcove/img/missing-video-2.gif`;
};
