import React from "react";
import renderHTML from "react-render-html";
import SubjectTopicCard from "../SubjectTopics/SubjectTopicCard";
import "./LearningObjectives.css";
import { Link } from "react-router-dom";

const LearningObjectives = ({
  learningObjectives,
  topic,
  topicSubcategories,
  subjectId,
  subjectColor
}) => {
  const scrollToViewMore = e => {
    e.preventDefault();
    document.querySelector("#suggested-activities-container").scrollIntoView({
      behavior: "smooth"
    });
    return;
  };

  return (
    <section className="learning-objectives">
      <div className="row">
        <div className="col-md-7 col-lg-8">
          <h2 className="learning-objectives__title">Learning Goals</h2>
          <span className="learning-objectives__text">
            {renderHTML(learningObjectives)}
          </span>
          <Link
            aria-label="Scroll to learning prompts"
            to={"#suggested-activities-container"}
            className="link-to-suggested-activities"
            onClick={e => scrollToViewMore(e)}
          >
            Explore associated learning prompts
          </Link>
        </div>
        <div className="col-md-5 col-lg-4 h-50 subject-topic-wrapper">
          <SubjectTopicCard
            topicName={topic}
            topicSubcategories={topicSubcategories}
            subjectId={subjectId}
            maxListItem={0}
            subjectColor={subjectColor}
          />
        </div>
      </div>
    </section>
  );
};
export default LearningObjectives;
