import React from "react";
import TitcHeader from "./Components/Header/Header";
import EduFooter from "./Components/Footer/Footer";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.css";
import "./Components/Common/Common.css";
import "./Components/Form/SearchForm.css";
import "./Components/App/App.css";
import SearchDetails from "./Components/Pages/SearchDetails";
import Home from "./Components/Pages/Home";
import SearchResults from "./Components/Pages/SearchResults";
import TopicDetails from "./Components/Pages/TopicDetails";
import SubjectTopics from "./Components/SubjectTopics/SubjectTopics";
import { DFOContextProvider } from "./context/dfoContent.context"

const EducationSearch = () => {
  return (
    <div className="main-container-wrapper">
      <Router>
        <TitcHeader />
        <Switch>
          <DFOContextProvider>
            <Route path="/" exact component={Home} />
            <Route path="/subjects/:subject" exact component={SubjectTopics} />
            <Route
              path="/search-educational-resources/:nid/:site"
              exact
              component={SearchDetails}
            />
            <Route
              path="/search-educational-resources/:term/:page/:category/:site/:division"
              exact
              component={SearchResults}
            />
            <Route
              path="/subjects/:subject/topics/:topic"
              exact
              component={TopicDetails}
            />
          </DFOContextProvider>
        </Switch>
      </Router>
      <EduFooter />
    </div>
  );
};

export default EducationSearch;
