import React, { useEffect, useState, useRef } from "react";
import "./SubjectTopics.css";
import SubjectCard from "../SubjectsCategory/SubjectCard";
import axios from "axios";
import SubjectTopicCard from "./SubjectTopicCard";
import propTypes from "prop-types";
import useFetch from "../hooks/useFetch";
import SubjectTopicsHeader from "./SubjectTopicsHeader";
import Head from "../Head/Head";
import BeatLoader from "react-spinners/BeatLoader";

const SubjectTopics = props => {
  const [topics, setTopics] = useState([]);
  const [loading, setLoading] = useState(false);
  const [numTopics, setNumTopics] = useState(0);
  const [topicsDisplayed, setTopicsDisplayed] = useState(0);
  const [newAdded, setNewAdded] = useState([]);
  const subjectId = props.match.params.subject;
  const [subjects, , , subjectsError] = useFetch(
    `${process.env.REACT_APP_TVO_CMS_URL}/subjects`
  );

  let imageUrl = "";
  let imageDesc = "";
  let subjectName = "";
  let subjectColor = "";
  const parent = "subject-topics";
  const loadMoreButtonRef = useRef();

  let headObj = {
    title: "",
    description: "",
    ogImage: "",
    ogTitle: "",
    ogDescription: ""
  };

  const removeDuplicatesByTopicsNames = topics => {
    const result = [];
    const map = new Map();
    for (const topic of topics) {
      if (!map.has(topic.name)) {
        map.set(topic.name, true);
        result.push(topic);
      }
    }
    return result;
  };

  useEffect(() => {
    const TVO_URL = process.env.REACT_APP_TVO_CMS_URL,
      TVOKIDS_URL = process.env.REACT_APP_TVOKIDS_URL,
      promiseTVO = axios.get(`${TVO_URL}/subject-list/${subjectId}`),
      promiseTVOKIDS = axios.get(`${TVOKIDS_URL}/subject-list/${subjectId}`);
    setLoading(true);
    let data = [];
    (async () => {
      try {
        await Promise.all([promiseTVO, promiseTVOKIDS])
          .then(resp => {
            data = removeDuplicatesByTopicsNames([
              ...resp[1].data.topics,
              ...resp[0].data.topics
            ]);
            setNumTopics(data.length);
          })

          .catch(err =>
            console.log("Error occurs while fetching topics -", err)
          );

        if (data && data.length > 0) {
          setTopics(data);

          if (data.length > 10) {
            setTopicsDisplayed(10);
          } else {
            setTopicsDisplayed(data.length);
          }
        } else {
          setTopics([]);
        }
        setLoading(false);
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    })();
  }, [subjectId]);

  if (subjects && subjects.length > 0 && !subjectsError) {
    const currentSubject = subjects.find(s => s.url_alias === "/" + subjectId);
    if (currentSubject) {
      subjectName = currentSubject.name;
      const imageArr =
        currentSubject.field_subject_banner_image &&
        currentSubject.field_subject_banner_image.split("||");
      if (imageArr) {
        imageUrl = imageArr[0];
        imageDesc = imageArr[1];
      }

      let metaTags = "";

      metaTags = currentSubject && currentSubject.metatags;

      if (metaTags) {
        headObj.title = metaTags.title;
        headObj.description = metaTags.description;
        headObj.ogImage = metaTags.og_image;
        headObj.ogDescription = metaTags.og_description;
        headObj.ogTitle = metaTags.og_title;
      }
      subjectColor = currentSubject.field_subject_banner_colour;
    } else return null;
  }

  let filterSubjects = subjects
    ? subjects.filter(subject => subject.url_alias !== "/" + subjectId)
    : [];

  const onHandleClickLoadMore = () => {
    loadMoreButtonRef.current.blur();
    if (numTopics - topicsDisplayed >= 10) {
      setTopicsDisplayed(prev => prev + 10);
    } else {
      setTopicsDisplayed(prev => prev + (numTopics - prev));
    }
    let content;
    if (numTopics > 0 && topicsDisplayed >= 10) {
      const numsOfSliceEnd =
        topicsDisplayed + Math.min(numTopics - topicsDisplayed, 10);
      content = topics.slice(topicsDisplayed, numsOfSliceEnd);
      setNewAdded(prevState => [...prevState, ...content]);
    }
  };

  const onKeyPressEnterOrSpaceLoadMore = e => {
    if (e.keyCode === 32 || e.keyCode === 13) {
      onHandleClickLoadMore();
    }
    return;
  };

  const onResetSubjects = () => {
    setNewAdded([]);
  };

  return (
    <div className="wrap-containers">
      <Head
        title={headObj.title}
        description={headObj.description}
        ogImage={headObj.ogImage}
        ogTitle={headObj.ogTitle}
        ogDescription={headObj.ogDescription}
      />
      <div
        role="main"
        className="main-topics-container container-fluid clearfix"
      >
        <SubjectTopicsHeader
          subject={subjectName}
          image={imageUrl}
          alt={imageDesc}
          introText={`Choose a topic below and connect to engaging TVO content and associated learning prompts that support the grade 7-8 curriculum.`}
          name="subject-landing-page"
          subjectId={subjectId}
          subjectColor={subjectColor}
          parent={parent}
        />
        <section className="block-subject-topics mb-5 w-100 container-fluid">
          {loading ? (
            <div className="d-flex my-5 justify-content-center align-items-center w-100">
              <BeatLoader color={"#ec1944"} loading={loading} />
            </div>
          ) : (
            <div className="row">
              {topics &&
                topics.map((topic, index) => {
                  let { id, name, subcategories, url_alias } = topic;
                  if (index < 10) {
                    return (
                      <div
                        className="col-md-6 col-lg-4 col-xl-3 card-group my-3 d-flex flex-row justify-content-center align-items-center"
                        key={id}
                      >
                        <SubjectTopicCard
                          topicId={url_alias.replace("/", "")}
                          topicName={name}
                          topicSubcategories={subcategories}
                          subjectId={subjectId}
                          maxListItem={3}
                          button
                          subjectColor={subjectColor}
                        />
                      </div>
                    );
                  }
                  return "";
                })}

              {newAdded &&
                newAdded.map((topic, index) => {
                  let { id, name, subcategories, url_alias } = topic;
                  return (
                    <div
                      className="col-md-6 col-lg-4 col-xl-3 card-group my-3 d-flex flex-row justify-content-center align-items-center"
                      key={`${id}-${Math.random()}`}
                    >
                      <SubjectTopicCard
                        topicId={url_alias.replace("/", "")}
                        topicName={name}
                        topicSubcategories={subcategories}
                        subjectId={subjectId}
                        button
                        subjectColor={subjectColor}
                      />
                    </div>
                  );
                })}

              {numTopics - topicsDisplayed > 0 && (
                <div
                  className="col-md-6 col-lg-4 col-xl-3 card-group my-3 d-flex flex-row justify-content-center add-more-wrapper"
                  onClick={onHandleClickLoadMore}
                  arial-label="Load 10 more topics"
                  onKeyDown={e => onKeyPressEnterOrSpaceLoadMore(e)}
                  tabIndex={0}
                  ref={loadMoreButtonRef}
                >
                  <div className="card subject-topic-card add-more my-md-3 my-lg-0">
                    <div className="card-body add-more-text d-flex flex-row justify-content-center align-items-center add-more-text">
                      <p className="topics-keyword">
                        Load More
                        <span className="topics-keyword d-block">Topics</span>
                      </p>
                    </div>
                  </div>
                </div>
              )}
            </div>
          )}
        </section>
        <section className="block-subject-others mb-5">
          <div className="card section-subject-others-wrapper w-100 mx-auto">
            <div className="p-4">
              <h2 className="block-subject-others__title card-title text-center">
                Additional resources curated with you in mind
              </h2>
              <div className="block-subject-others__text-wrapper">
                <p className="block-subject-others__text card-text text-center">
                  Choose another subject below and explore ways to support
                  further learning of the grade 7-8 curriculum.
                </p>
              </div>
            </div>
            <div className="row d-flex flex-row justify-content-around">
              {filterSubjects &&
                !loading &&
                filterSubjects.map(
                  (
                    {
                      name,
                      tid,
                      url_alias,
                      field_subject_banner_image,
                      field_subject_banner_colour
                    },
                    idx
                  ) => {
                    const imageArr =
                      field_subject_banner_image &&
                      field_subject_banner_image.split("||");
                    let subjectImgUrl;
                    let subjectImgDesc;
                    if (imageArr) {
                      subjectImgUrl = imageArr[0];
                      subjectImgDesc = imageArr[1];
                    }
                    return (
                      <div className="col-12 col-md-4 card-group p-2" key={tid}>
                        <SubjectCard
                          subjectName={name}
                          subjectId={url_alias.replace("/", "")}
                          imageUrl={subjectImgUrl}
                          imageDesc={subjectImgDesc}
                          subjecColor={field_subject_banner_colour}
                          index={idx}
                          subjects={subjects}
                          role="link"
                          aria-label={name}
                          handleClick={onResetSubjects}
                        />
                      </div>
                    );
                  }
                )}
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

SubjectTopics.propTypes = {
  subjectId: propTypes.string,
  subjectName: propTypes.string,
  imageUrl: propTypes.string,
  imageDesc: propTypes.string,
  subjects: propTypes.array
};

export default SubjectTopics;
