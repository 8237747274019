import React from "react";
import "./Footer.css";
import FooterLogo from "./logo.footer.svg";

const date = new Date().getFullYear();

const EduFooter = () => {
  return (
    <footer className="react-footer">
      <section className="footer footer-first">
        <div className="footer-first-right">
          <a
            href={`${process.env.REACT_APP_TVO_URL}/contact`}
            target="_blank"
            className="feedback-link"
            rel="noopener noreferrer"
            tabIndex={0}
          >
            Share your feedback
            <i className="icon-speech-bubble"></i>
          </a>
        </div>
        <div className="footer-first-left">
          <a
            className="footer-logo"
            href={`${process.env.REACT_APP_TVO_URL}`}
            target="_blank"
            title="Link to TVO.org"
            rel="noopener noreferrer"
            tabIndex={0}
          >
            <img
              className="footer-logo-img"
              height="37"
              src={FooterLogo}
              alt="Link to TVO.org"
            />
          </a>
        </div>
      </section>
      <section className="footer-last">
        <div className="footer-content-container">
          <p className="footer-text">
            <a
              href={`${process.env.REACT_APP_TVO_URL}/terms-of-use`}
              target="_blank"
              rel="noopener noreferrer"
              tabIndex={0}
            >
              Terms of Use{" "}
            </a>{" "}
            |
            <a
              href={`${process.env.REACT_APP_TVO_URL}/privacy-policy`}
              target="_blank"
              rel="noopener noreferrer"
              className="secondA"
              tabIndex={0}
            >
              Privacy Policy
            </a>{" "}
            |
            <a
              href={`${process.env.REACT_APP_TVO_URL}/copyright`}
              target="_blank"
              rel="noopener noreferrer"
              className="thirdA"
              tabIndex={0}
            >
              Copyright
            </a>
            - TVO is a registered charity #85985 0232 RR0001 -{" "}
            <span className="copyright_text">
              Copyright © {date} The Ontario Educational Communications
              Authority (TVO)
            </span>
          </p>
        </div>
      </section>
    </footer>
  );
};
export default EduFooter;
