import React from "react";
import { useHistory } from "react-router-dom";
import renderHTML from "react-render-html";
import propTypes from "prop-types";
import "./SubjectTopicsHeader.css";
import { generateBreadCrumb } from "../Breadcrumb/Breadcrumb";

const SubjectTopicsHeader = ({
  subject,
  image,
  alt,
  introText,
  name,
  subjectColor,
  subjectId,
  parent
}) => {
  const history = useHistory();
  const pathArr = history.location.pathname.split("/");

  const breadCrumb = generateBreadCrumb(pathArr, subject, subjectId, introText);
  return (
    <section className="block-subject-intro mb-2 mb-md-3">
      {breadCrumb && breadCrumb}
      <div className="d-flex flex-column justify-content-center align-items-center">
        {parent === "subject-topics" && (
          <h1
            className="block-subject-intro__title text-center my-2"
            style={{ color: subjectColor }}
          >
            {renderHTML(subject)}
          </h1>
        )}
        {parent === "topic-details" && (
          <p
            className="block-subject-intro__title text-center my-2"
            style={{ color: subjectColor }}
          >
            {renderHTML(subject)}
          </p>
        )}

        <div className="block-subject-intro__text-wrapper">
          {parent === "subject-topics" && (
            <p className="block-subject-intro__text text-center">
              {renderHTML(introText)}
            </p>
          )}
          {parent === "topic-details" && (
            <h1 className="block-subject-intro__text text-center mb-3">
              {renderHTML(introText)}
            </h1>
          )}
        </div>
      </div>
    </section>
  );
};

SubjectTopicsHeader.propTypes = {
  subject: propTypes.string,
  image: propTypes.string,
  alt: propTypes.string,
  introText: propTypes.string,
  parent: propTypes.string
};

export default SubjectTopicsHeader;
