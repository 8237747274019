import React, { createContext, useEffect, useState } from 'react'
import axios from 'axios';

export const DFOContentContext = createContext([]);

export const DFOContextProvider = props => {
  const [dfoContent, setDfoContent] = useState([]);
  useEffect(() => {
    const DFOContentUrl = process.env.REACT_APP_DFO_CONTENT_S3_URL;
    try {
      (async () => {
        const { data } = await axios.get(DFOContentUrl);
        if (data) {
          setDfoContent(data);
        } else {
          throw new Error('Error occurs while fetching DFO data');
        }
      })();
    } catch (error) {
      console.log(error);
    }
  }, [])

  return (
    <DFOContentContext.Provider
      value={{ dfoContent }}
    >
      {props.children}
    </DFOContentContext.Provider>
  )
}

export const kindergarden = {
  k: true,
  K: true,
  1: true,
  2: true,
  3: true,
  4: true
}

export const juniorHigh = {
  5: true,
  6: true,
  7: true,
  8: true,
}

export const highschool = {
  9: true,
  10: true,
  11: true,
  12: true,
}
