import React from "react";
import NODESearch from "../NODESearch";
import propTypes from "prop-types";

const TopicResources = ({ type, nodeid, site }) => {
  let slugSite = "";
  if (site === "tvo") {
    slugSite = process.env.REACT_APP_TVO_ENGINE_SLUG;
  } else {
    slugSite = process.env.REACT_APP_KIDS_ENGINE_SLUG;
  }
  return (
    <NODESearch
      type={type}
      nodeid={nodeid}
      site={slugSite}
      topic={true}
      from="topic-resources"
    />
  );
};

TopicResources.propTypes = {
  type: propTypes.string,
  nodeid: propTypes.string,
  site: propTypes.string
};

export default TopicResources;
