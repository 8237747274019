import React, { Fragment } from "react";
import { Helmet } from "react-helmet";

const Head = ({ title, description, ogImage, ogTitle, ogDescription }) => {
  return (
    <Fragment>
      <Helmet>
        <title>{title}</title>
        <meta name="robots" content="noindex" />
        <meta property="og:title" content={ogTitle} />
        <meta name="description" content={description} />
        <meta property="og:description" content={ogDescription} />
        <meta property="og:image" content={ogImage} />
      </Helmet>
    </Fragment>
  );
};

export default React.memo(Head);
