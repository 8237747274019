import React from "react";
import "./MathifyBanner.css";
const MathifyBanner = () => {
  return (
    <section
      id="block-tvo-citc-mathify-banner"
      className="tvo-citc-mathify-banner clearfix"
    >
      <div className="mathify-banner-container">
        <div className="container">
          <img
            className="img-fluid border-0 mx-auto d-block mb-3"
            src={require("../../assets/images/img-logo-mathify.svg")}
            alt="tvo classroom"
            width="213"
          />
          <p className="text-center">
            Complement grade 4-12 math learning, with a free personalized math
            coach to boost math skills and confidence. Available when students
            are ready to learn. A new math question bank is coming soon to
            further support the learning!
          </p>
          <p className="text-center">
            <a
              className="link"
              href="https://www.tvomathify.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              Visit TVO Mathify
            </a>
          </p>
        </div>
      </div>
    </section>
  );
};
export default MathifyBanner;
