import React, { useEffect, useState } from "react";
import "./SubjectTopics.css";
import propTypes from "prop-types";
import "./SubjectTopicCard.css";
import { useHistory } from "react-router-dom";

const SubjectTopicCard = ({
  topicId,
  topicName,
  topicSubcategories,
  subjectId,
  maxListItem,
  button,
  subjectColor
}) => {
  const [subCateogories, setSubCategories] = useState([]);
  const history = useHistory();

  useEffect(() => {
    if (topicSubcategories && topicSubcategories.length > 0) {
      let subjectName = subjectId.charAt(0).toUpperCase() + subjectId.slice(1);
      let filterSubcategories = topicSubcategories.filter(
        subcategory => subcategory.name !== subjectName
      );
      setSubCategories(filterSubcategories);
    } else {
      setSubCategories([]);
    }
  }, [subjectId, topicSubcategories]);
  return (
    <div className="card subject-topic-card my-md-3 my-lg-0 h-100">
      <div
        className="subject-topic-card__header"
        style={{ backgroundColor: subjectColor }}
      ></div>
      <div className="card-body py-3 d-flex flex-column justify-content-between">
      <div>
          <h2 className="card-title my-2">{topicName}</h2>
          <ul className="list-group list-group-flush">
            {subCateogories &&
              subCateogories.length > 0 &&
              subCateogories.map(({ id, name }, index) => {
                if (maxListItem > 0) {
                  if (index < maxListItem) {
                    return (
                      <li key={`${subjectId}-${id}`} className="pb-2">
                        {name}
                      </li>
                    );
                  }
                } else {
                  return (
                    <li key={`${subjectId}-${id}`} className="pb-2">
                      {name}
                    </li>
                  );
                }
                return "";
              })}
          </ul>
        </div>
        {button && (
          <div className="text-center card-button-container">
            <input
              type="button"
              className="button js-form-submit form-submit btn-primary mx-auto"
              name="viewTopic"
              value="Show Details"
              onClick={() =>
                history.push(`/subjects/${subjectId}/topics/${topicId}`)
              }
              arial-label={`Show Details for ${topicName}`}
            />
          </div>
        )}
      </div>
    </div>
  );
};

SubjectTopicCard.propTypes = {
  topicName: propTypes.string,
  topicSubcategories: propTypes.array,
  subjectId: propTypes.string,
  button: propTypes.bool,
  isGrey: propTypes.bool
};

export default React.memo(SubjectTopicCard);
