import React from "react";
import { Link } from "react-router-dom";
import renderHTML from "react-render-html";
import "./Breadcrumb.css";

export const generateBreadCrumb = (path, ...rest) => {
  const SEARCH_RESULT = "Search Result";
  const pathLength = path && path.length;

  let result = null;
  // set the current search term to be the value in session storage or empty
  const currentSearchTerm = sessionStorage.getItem("search-term") || "%20";
  const currentSelectedGrade = sessionStorage.getItem("select-grades") || "%20";
  const resultFrom =
    currentSelectedGrade === "tvoelementary" ||
    currentSelectedGrade === "tvosecondary"
      ? "tvo-org"
      : "tvo-kids";

  switch (pathLength) {
    case 3:
      if (path.includes("subjects")) {
        result = (
          <div className="breadcrumbs-container">
            <nav className="breadcrumb">
              <Link
                className="breadcrumb-item breadcrumb-navigation-item"
                to="/"
                title="Home"
                arial-label="Back to home page"
              >
                Home
              </Link>
              {rest[0] && <span className="breadcrumb-item">&gt;</span>}
              <span className="breadcrumb-item">{renderHTML(rest[0])}</span>
            </nav>
          </div>
        );
      }
      break;
    case 4:
      if (path.includes("search-educational-resources")) {
        result = (
          <div className="breadcrumbs-container breadcrumbs-container-mt search-detail-my">
            <nav className="breadcrumb">
              <Link
                className="breadcrumb-item breadcrumb-navigation-item"
                to="/"
                title="Home"
                arial-label="Back to home page"
              >
                Home
              </Link>
              <span className="breadcrumb-item">&gt;</span>
              {rest[0].location.state === "search-result" ? (
                <Link
                  className="breadcrumb-item breadcrumb-navigation-item"
                  to={`/search-educational-resources/${currentSearchTerm}/1/all/${resultFrom}/${currentSelectedGrade}`}
                  title="Search Result"
                  arial-label="Back to search result"
                >
                  {SEARCH_RESULT}
                </Link>
              ) : (
                <Link
                  className="breadcrumb-item breadcrumb-navigation-item"
                  to={``}
                  title="Search Result"
                  arial-label="Back to search result"
                >
                  {SEARCH_RESULT}
                </Link>
              )}
              <span className="breadcrumb-item">&gt;</span>
              <span className="breadcrumb-item">
                {rest[1] && renderHTML(rest[1])}
              </span>
            </nav>
          </div>
        );
      }
      break;
    case 5:
      if (path.includes("topics")) {
        result = (
          <div className="breadcrumbs-container">
            <nav className="breadcrumb">
              <Link
                className="breadcrumb-item breadcrumb-navigation-item"
                to="/"
                title="Home"
                arial-label="Back to home page"
              >
                Home
              </Link>
              {rest[0] && <span className="breadcrumb-item">&gt;</span>}
              <Link
                className="breadcrumb-item breadcrumb-navigation-item"
                to={`/subjects/${rest[1]}`}
                title={rest[0]}
                arial-label={`Back to ${rest[0]} page`}
              >
                {renderHTML(rest[0])}
              </Link>
              {rest[2] && <span className="breadcrumb-item">&gt;</span>}
              <span className="breadcrumb-item">{renderHTML(rest[2])}</span>
            </nav>
          </div>
        );
      }
      break;
    case 7:
      if (path.includes("search-educational-resources")) {
        result = (
          <div
            className="breadcrumbs-container"
            style={{ marginBottom: "0px" }}
          >
            <nav className="breadcrumb">
              <Link
                className="breadcrumb-item breadcrumb-navigation-item"
                to="/"
                title="Home"
                arial-label="Back to home page"
              >
                Home
              </Link>
              <span className="breadcrumb-item">&gt;</span>
              <span className="breadcrumb-item">{SEARCH_RESULT}</span>
            </nav>
          </div>
        );
      }
      break;
    default:
      break;
  }

  return result;
};
