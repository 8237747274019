import React from "react";
import renderHTML from "react-render-html";
import "./SuggestedActivities.css";

function SuggestedActivities(props) {
  var Items = { props };
  var activityList = Items.props.activities;
  var subjectColor = Items.props.subjectColor;
  let itemsToRender;
  if (activityList) {
    var termID = Items.props.tid;
    var heading = <h2>Associated learning prompts</h2>;
    var learning_subtext = (
      <p className="learning-activities-text">
        Choose any of the suggested learning prompts below to directly connect
        to grade 7-8 curriculum expectations.
      </p>
    );
    itemsToRender = activityList.map((item, i) => {
      return (
        <div
          key={termID + i}
          className="row list-group-item"
          style={{ borderColor: subjectColor }}
        >
          <div
            className="col-md-2 counter order-md-2"
            style={{ color: subjectColor }}
          >
            {("0" + (i + 1)).slice(-2)}
          </div>
          <div className="col-md-10 order-md-1">{renderHTML(item.value)}</div>
        </div>
      );
    });
  } else {
    itemsToRender = "";
  }

  return (
    <section id="suggested-activities-container" className="activity-container">
      {heading}
      {learning_subtext}
      {itemsToRender}
    </section>
  );
}
export default SuggestedActivities;
