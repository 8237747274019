import { useEffect, useState } from "react";
import axios from "axios/index";

export const useTopics = match => {
  const [result, setResult] = useState("");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    let topic = match.params.topic;
    const TVO_URL = `${process.env.REACT_APP_TVO_CMS_URL}/topic-list/${topic}`;
    const TVOKIDS_URL = `${process.env.REACT_APP_TVOKIDS_URL}/topic-list/${topic}`;
    const tvoPromise = axios.get(TVO_URL);
    const tvoKidsPromise = axios.get(TVOKIDS_URL);
    let combineResult = [];

    setLoading(true);
    const getResult = async () => {
      await Promise.all([tvoPromise, tvoKidsPromise])
        .then(resp => {
          let numArr = 0;
          if (resp[numArr].data.topics.length === 0) {
            numArr = 1;
          }
          combineResult = resp[numArr];
          if (numArr === 0) {
            numArr = 1;
          } else if (numArr === 1) {
            numArr = 0;
          }
          if (resp[numArr].data.topics.contents) {
            if (resp[numArr].data.topics.contents.length) {
              combineResult.data.topics.contents = [
                ...combineResult.data.topics.contents,
                ...resp[numArr].data.topics.contents
              ];
            }
          }
          setResult(combineResult.data);
          setLoading(false);
        })
        .catch(err => {
          setLoading(false);
          console.log("Error occurs fetching topics", err);
        });
    };
    getResult();
  }, [match]);
  return [result, loading];
};
