import React from "react";
import axios from "axios";
import "../../index.css";
import "./FeaturedBlocks.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExternalLinkAlt } from "@fortawesome/free-solid-svg-icons";
import SponsoredContentLabel from "../SponsoredContentLabel/SponsoredContentLabel";

class FeaturedBlocks extends React.Component {
  state = {
    loading: true,
    output: [],
    data: []
  };

  componentDidMount() {
    var TVOURL = process.env.REACT_APP_TVO_CMS_URL;
    this.setState({ loading: true });

    axios
      .get(`${TVOURL}/titc/featured_blocks`)
      .then(res => {
        const data = res.data;
        this.setState({ data, loading: false });
      })
      .catch(error => console.log(error));
  }

  render() {
    return (
      <div>
        {this.state.loading ? (
          ""
        ) : (
          <div className="wrapper-featured-resources row justify-content-center my-3 my-md-4">
            <h2 className="text-center">Featured Resources</h2>
            <div className="row row-eq-height">
              {this.state.data.map((items, i) => {
                const isSponsored = items.isSponsored === "1" && "is-spnsr";
                if (i < 3) {
                  return (
                    <a
                      href={items.link.replace("titcapi.tvo.org", "www.tvo.org")}
                      className={`col-xs-12 col-sm-6 col-md-4 col-lg-4 featured-card-wrapper ${items.type} ${isSponsored}`}
                      data-citc="data-citc-featured"
                      data-citc-value={items.title}
                      target="_blank"
                      key={i}
                      rel="noopener noreferrer"
                    >
                      <div className="featured-card">
                        <div className={`thumbnail-wrap ${items.type}`}>
                          <img
                            src={items.image["#uri"]}
                            alt={items.image["#alt"]}
                            typeof="foaf:Image"
                            className="img-responsive"
                          />
                          <div className="thumbnail-overlay">
                            {items.type === "program"
                              ? "Video"
                              : items.type.charAt(0).toUpperCase() +
                                items.type.slice(1)}
                          </div>
                        </div>
                        <div className="card-content">
                          {/* <div className="tag">{items.category}</div> */}
                          {items.isSponsored === "1" && (
                            <SponsoredContentLabel
                              text="Sponsored Content"
                              from="feature-block"
                            />
                          )}
                          <div className="title">
                            {items.title}
                            {items.isSponsored === "1" && (
                              <FontAwesomeIcon
                                icon={faExternalLinkAlt}
                                size="xs"
                                color="#007FA3"
                              />
                            )}
                          </div>
                          <div className="spnsr-by-message">
                            {items.sponsoredByMessage}
                          </div>
                          <div className="desc">{items.description}</div>
                          <div className="spnsr-link">
                            {items.sponsoredLink}
                          </div>
                        </div>
                      </div>
                    </a>
                  );
                }
                return null;
              })}
            </div>
          </div>
        )}
      </div>
    );
  }
}
export default FeaturedBlocks;
