import React from "react";
import "./Header.css";
import Logo from "./logo.svg";
import { Link, withRouter, useHistory } from "react-router-dom";
const TitcHeader = () => {
  const history = useHistory();

  const keypressForEnterOrSpacebar = e => {
    if (e.keyCode === 32 || e.keyCode === 13) {
      history.push("/");
    }
    return;
  };

  return (
    <header
      id="secondary-header"
      className="glazed-secondary-header navbar-fixed-top clearfix hidden-none"
      role="banner"
    >
      <div className="secondary-header-container">
        <div className="wrap-branding">
          <Link
            className="logo navbar-btn"
            to="/"
            title="Home"
            rel="home"
            arial-label="Back to home page"
            tabIndex={1}
            onKeyDown={e => keypressForEnterOrSpacebar(e)}
          >
            <img id="logo" src={Logo} alt="Home" tabIndex="-1" />
          </Link>
          <div className="tvo-link-wrapper">
            <a
              className="tvo-link"
              href={process.env.REACT_APP_TVO_URL}
              target="_blank"
              rel="noopener noreferrer"
            >
              Back to TVO.org
            </a>
          </div>
        </div>
      </div>
    </header>
  );
};
export default withRouter(TitcHeader);
