import React from "react";
import "./SponsoredContentLabel.css";

const SponsoredContentLabel = ({ text, from }) => {
  const repositionFrom = from === "feature-block" ? "spnsr-content-feature-block" : "spnsr-content-search";
  return (
    <div className={`spnsr-content ${repositionFrom}`}>
      <p>{text}</p>
    </div>
  );
};

export default SponsoredContentLabel;
